@use 'styles/globals/mixins/font';


.block{
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style-type: none;
    width: 100%;
    flex: 1 0 auto;
}

.item{
    display: flex;
    width: 100%;
    padding: 2rem;
    border-bottom: 1px solid #ccc;
    flex: 1 0 auto;
}

// .chat{
//     padding: 0 0 1rem 0;
// }

.link{
    display: flex;
    align-items: center;
    align-content: center;
    background-color: #fff;
    width: 100%;
    justify-content: flex-start;
    padding: 0;   

    & [class*="Chat_icon"]{
        height: 25px
    }

    & [class*="Chat_fullSize"]{
        display: block
    }
}

.text{
    @include font.dynamic-font(18, 28);
    line-height: 1;
    color: initial;
    margin-left: 2rem;
}

.cartBadge{
    margin-left: 2rem;
}


