@use "styles/globals/placeholders" as *;
@use 'styles/globals/breakpoints';


.block{
    @extend %tableBlock;
}

.row{
    @extend %tableRow;
}

.cell{
    @extend %tableCell;
    width: 50%;
}

.cellContent{
    @extend %tableCellContent;
    @media screen and (max-width: breakpoints.$screen-lg) {
           display: inline;
        }
}

.caps{
    text-transform: uppercase !important;
}

.gridSplit{
    grid-row: 2/4;
}

.dimensionImg{
    margin: 4rem 4rem 0 4rem;
    width: 80%;
    height: auto;
    object-fit: contain;

    @media screen and (max-width: breakpoints.$screen-md) {
        width: 100%;
        margin: 4rem 0 0 0;
    }
}

.legacyTable{
    width: 100%;
    display: flex;
    flex-direction: column;

    & table{
        @extend .block;
        margin-bottom: 4rem
    }

    & tr{
        @extend .row;
    }

    & td{
        @extend .cell;
        display: table-cell;
        flex: 1 0 auto;
    }

    & thead{
        border-bottom: 1px solid #ccc;

        & + tbody td{
            width: 20%;
        }
    }

    & th{
        font-size: 1.2rem;
        width: 20%;
    }

    & img{
        width: 100px;
        height: 100px;
    }
}
