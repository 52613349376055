@use 'styles/globals/breakpoints';


.button{
    display: flex;
    width: 100%;
    padding: 2rem;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    border-top: 1px solid #eee;
    flex: 0;
    color: inherit;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        max-height: 100px; 
    }

    @media only screen and (min-width: breakpoints.$screen-lg ) {
        padding: 4rem 2rem;  
    }
    
}

.icon{
    flex: 1 0 auto;
    transform: rotate(180deg)
}

.text{
    line-height: 1.2;
    margin: 0;
    font-weight: 800;
    font-size: 1.8rem;
    padding: 0 1.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.small{
    font-weight: 400;
    font-size: 1.1rem;
}
