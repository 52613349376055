@use "styles/globals/placeholders" as *;
@use 'styles/globals/breakpoints';


.block{
    margin: 4rem 0 4rem 0;
    break-inside: avoid;
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    position: relative;
    transition: box-shadow 0.3s ease-in-out;

    &:hover{
        box-shadow: var(--box-shadow);

        @media print{
            box-shadow: none;
        }
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        box-shadow: var(--box-shadow);
    }

    @media print{
        margin-bottom: 0;
    }
}

.heading{
    margin: 0 0 0 1rem;
}

.tableBlock{
    margin-top: 4rem;
    @extend %tableBlock;
}

.row{
    @extend %tableRow;
    width: 100%;
}

.headingCell{
    @extend %tableHeadingCell;
    padding-left: 1rem;

    &:first-of-type{
        width: 100px;
    }
}

.cell{
    @extend %tableCell;
    font-weight: inherit !important;
    padding-left: 1rem;

    &:first-of-type{
        width: 100px;
    }
}

.cellContent{
    @extend %tableCellContent;
}

.zebraTable{
    @extend %zebraTable;
}
@media only screen and (max-width: breakpoints.$screen-sm ) {

    .cell {
        font-size: 70%;
        word-break: break-word;
    }
    .headingCell {
              
        font-size: 60%;
        word-break: break-word;
            &:first-of-type {
                    width: 50px;
                }
}
}