
@media print{
    .title{
        font-size: 1.8rem !important;
        text-align: center;
        width: 100%;
    }

    .meta{
        font-size: 1rem !important;
    }
}

