@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block{
    position: sticky;
    top: 100px;
    width: 100%;
    // min-height: 500px;
    margin-top: 2rem;
    
    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: flex;
        flex-direction: column;
    }
}


.hoverImage{
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: auto;
    z-index: 9999;
}

.mainSlider{
    position: relative;
    width: 100%;
    min-height: 350px;
}

.thumbSlider{
    margin-top: 6rem;
    position: relative;
}

.thumbnailCntr{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.navigateBtn{
    background-color: transparent;
    line-height: 1;
    display: flex;
    align-items: center;

    & svg{
        fill: var(--indigo);
    }

    &:disabled{
        opacity: .25;
        cursor: not-allowed;
    }
}

.active{
    & .thumbButton{
        border-bottom: 2px solid var(--ice);
    }
}

.thumbOverlay{
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hide{
    display: none !important;
}

.videoPlayArrow{
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 35px solid var(--white);
    width: 35px;
    height: 35px;
    opacity: .8;
}


.mainImage{
    object-fit: contain;
    width: 100%;
    height: auto;

    & .iiz__hint{
        bottom: 46px !important;
    }
}

.badgeCntr{
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: 10%;
    display: flex;
    justify-content: center;
    
    & [class^="Flags_block"]{
        width: 60%;

        @media only screen and (max-width: breakpoints.$screen-sm ) {
            width: 90% !important;
        }
    }
}

.slider{
    display: block;
}

.dotCntr{
    position: absolute;
    bottom: -100px;
    list-style: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0;
    margin: 0;
    width: 100%;

    & li{
        margin: 0 2rem
    }
}

.videoCntr{
    position: relative;
    overflow: hidden;
    // padding-top: 56.25%;
    width: 100%;

    & iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.thumbButton{
    background-color: transparent;
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid transparent;
}

.clickZoom{
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    font-size: 2.1rem;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: var(--border-radius);
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25%;
    opacity: 0;
    transition: all .4s ease;
}

.clickZoomActive{
    bottom: 20%;
    opacity: 1;
    transition: all .4s ease;

    animation: hide;
    animation-delay: 6s;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
}

.clickZoomHide{
    animation: hide;
    animation-delay: 5s;
    animation-duration: 0.7s;
    opacity: 0;
}

.zoomIcon{
    width: 30px;
    height: 30px;
    fill: var(--white);
    margin-right: 1rem;
}

.zoomText{
    @include font.dynamic-font(14, 18);
}

@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
